import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import OrcidIcon from '../assets/images/ORCID-iD_icon_BW_32x32.png';
import OrcidReversedIcon from '../assets/images/ORCID-iD_icon_reversed_32x32.png';
import ondrej from '../assets/images/ondrej.strnad.jpg';
import '../assets/styles/Main.scss';

function Main() {

  return (
    <div className="container">
      <div className="about-section">
        <div className="image-wrapper">
          <img src={ondrej} alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">            
            <a href="https://www.linkedin.com/in/ondrejstrnad/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
            <a href="https://www.facebook.com/ondrej.strnad.33" target="_blank" rel="noreferrer"><FacebookIcon/></a>
            <a href="https://www.instagram.com/ondrej__strnad/" target="_blank" rel="noreferrer"><InstagramIcon/></a>
            <a href="https://github.com/ondrejstrnad" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://orcid.org/0000-0002-8077-4692" target="_blank" rel="noreferrer"><img src={OrcidIcon}/></a>
          </div>
          <h1>Ondrej Strnad</h1>
          <p>Research Scientist / Full Stack Developer</p>

          <div className="mobile_social_icons">            
            <a href="https://www.linkedin.com/in/ondrejstrnad/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
            <a href="https://www.facebook.com/ondrej.strnad.33" target="_blank" rel="noreferrer"><FacebookIcon/></a>
            <a href="https://www.instagram.com/ondrej__strnad/" target="_blank" rel="noreferrer"><InstagramIcon/></a>
            <a href="https://github.com/ondrejstrnad" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://orcid.org/0000-0002-8077-4692" target="_blank" rel="noreferrer"><img src={OrcidIcon}/></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;